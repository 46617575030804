import React, { Component } from 'react'
import SystemHeader from './SystemHeader';

document.body.classList.add("admin");

export class Dashboard extends Component {
    render() {
        return (
            <div>
            <SystemHeader title="Yfirlit" />
            <div className="yfirlit">
                <div className='alpha'>
                    <h3>HAExpert</h3>
                </div>
                <div className='alpha'>
                    <h3>Almenn líðan</h3>
                </div>
                <div className='gamma'>
                    <h3>Tíðni sýkinga (Ig)</h3>
                </div>
            </div>
        </div>
        )
    }
}

export default Dashboard