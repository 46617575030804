import React from 'react'
import { NavLink } from 'react-router-dom';

import './Sidebar.css';

function SidebarAdmin() {
    return (
        <div className="sidebar">
            <h1><NavLink to="/admin/" activeClassName="selected">Fruma</NavLink></h1>
            <h4>Almennt</h4>
            <ul>
                <li><NavLink to="/admin/stjornbord" activeClassName="selected"><i className="fa-light fa-grid-2"></i> Yfirlit</NavLink></li>
            </ul>
            <h4>Greiningartól</h4>
            <ul>
                <li><NavLink to="/admin/hae" activeClassName="selected"><i className="fa-regular fa-monitor-waveform"></i> HAExpert</NavLink></li>
                <li><NavLink to="/admin/igexpert" activeClassName="selected"><i className="fa-regular fa-pills"></i> IgXpert</NavLink></li>
            </ul>
            <h4>Spurningalistar</h4>
            <ul>
                <li><NavLink to="/admin/qol" activeClassName="selected"><i className="fa-sharp fa-regular fa-heart-pulse"></i> Lífsgæðalistinn</NavLink></li>
            </ul>
            <h4>Kerfið</h4>
            <ul className="actionBtn">
                <li><NavLink to="/admin/patients" activeClassName="selected"><i className="fa-solid fa-hospital-user"></i> Sjúklingar</NavLink></li>
                <li><NavLink to="/admin/staff" activeClassName="selected"><i className="fa-sharp fa-solid fa-users"></i> Starfsfólk</NavLink></li>
            </ul>
        </div>
    )
}

export default SidebarAdmin;